import './App.css';
import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {


  const [phone, setPhone] = useState('');
  const [exam, setExam] = useState('');
  const [date, setDate] = useState('');
  const [result, setResult] = useState([]);
  const [options,setOptions]=useState([])
    const [status,setStatus]=useState('normal')
  const [loading, setLoading] = useState();

  const [currentDateTime, setCurrentDateTime] = useState('');


  const handleSubmit = async (e) => {
      // setLoading(true)
      setStatus('loading')
    e.preventDefault();
    const data = {
      phone:phone,
       exam: exam,
        exam_date:date
    };
      try {
        const response = await fetch('https://admin-panel-bigbooster.bigbooster.in/get-result-by-phone', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const data1= await response.json()
          if(data1.data.length>0){
              setResult(data1.data)
              debugger
              setStatus('res')
          }else{
              setStatus('non')
          }
      } catch (error) {
        console.error('Error:', error);
      }
  };

    const getAll = async () => {


        try {
            const response = await fetch('https://admin-panel-bigbooster.bigbooster.in/get-result-exam', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data1= await response.json()

            setOptions(data1.data)
        } catch (error) {
            console.error('Error:', error);
        }
    };


    useEffect(() => {
        getAll()
    }, []);
  return (
    <div className="App">

 <div className="min-h-screen flex items-center justify-center">

            <div className="grid m-2 grid-cols-3 max-sm:grid-cols-1 gap-4 bg-transparent">
                <div className="mt-auto mb-auto text-right max-sm:text-center">
                    <h1 className="text-3xl font-bold max-sm:text-2xl">Bigbooster Result Portal</h1>
                    <p className="py-6">Download Bigbooster App and ace your exam</p>
                </div>
                <div className="col-span-2">
                    {
                        status=="normal"?
                            <form className="card bg-transparent" onSubmit={handleSubmit}>
                                <div className="card-body max-sm:p-0">
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Phone Number</span>
                                        </label>
                                        <input
                                            type="number"
                                            placeholder="Phone"
                                            className="input rounded-[5px] input-bordered placeholder:text-xs focus:outline-none"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Select Exam Date</span>
                                        </label>
                                        <select
                                            className="select select-sm border !outline-none border-black w-full"
                                            required
                                            value={exam}
                                            onChange={(e) => setExam(e.target.value)}
                                        >
                                            <option disabled value="">Open and select</option>
                                            {
                                                options?.map((item,key)=>(
                                                    <option key={key} value={item.exam}>{item.exam}</option>
                                                ))
                                            }

                                        </select>
                                    </div>

                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Select date</span>
                                        </label>
                                        <select
                                            className="select select-sm border !outline-none border-black w-full"
                                            required
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                        >
                                            <option disabled value="">Open and select</option>
                                            {
                                                options?.map((item,key)=>(
                                                    <option key={key} value={item.exam_date}>{item.exam_date}</option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    {
                                        loading?
                                            <div className="form-control mt-6">
                                                <button className="btn btn-neutral rounded-[5px] capitalize" type="submit">
                                                    <span className="loading loading-spinner loading-xs"></span>
                                                    Loading...
                                                </button>
                                            </div>
                                            :
                                            <div className="form-control mt-6">
                                                <button className="btn btn-neutral rounded-[5px] capitalize" type="submit">
                                                    Check
                                                </button>
                                            </div>
                                    }
                                </div>
                            </form>
                            :status=="loading"?
                                <span className="loading loading-ring loading-lg"></span>
                                :status=="res"?
                                    <div className="card w-full max-sm:w-96 rounded-[5px] bg-[#02B290] shadow-xl">
                                        <div className="card-body">

                                            <div className="grid grid-cols-2">
                                                <div className="stats !rounded-[2px] shadow">
                                                    <div className="stat bg-[#CAD5E2]  !text-left !rounded-[2px]">
                                                        <p className="card-title">{result[0].student_name}</p>
                                                        <p className="text-xl "><b>Exam </b> {result[0].exam}</p>
                                                        <p className="text-xl"><b>Exam Date</b> {result[0].exam_date}</p>
                                                    </div>
                                                </div>
                                                <div className="stats !rounded-[2px] shadow">
                                                    <div className="stat bg-[#242B2E] text-white !rounded-[2px]">
                                                        <div className="stat-title text-white">Rank</div>
                                                        <div className="stat-value">{result[0].student_rank}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stats !rounded-[2px] stats-vertical lg:stats-horizontal shadow">

                                                <div className="stat">
                                                    <div className="stat-title">Percentage</div>
                                                    <div className="stat-value">{result[0].percentage}</div>
                                                </div>

                                                <div className="stat">
                                                    <div className="stat-title">Score</div>
                                                    <div className="stat-value">{result[0].score}</div>
                                                </div>

                                                <div className="stat !text-left">
                                                    <div className="stat-title">
                                                        <p><b>Right Answers Count </b>{result[0].right_answers}</p>
                                                        <p><b>Wrong Answers Count </b>{result[0].wrong_answers}</p>
                                                        <p><b>Blank Count </b>{result[0].blank_answers}</p>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>: status=='non'?
                                        <div className="card-body">
                                            <h2 className="card-title">No record Found!</h2>
                                            <p>Please check the mobile number or exam or date</p>
                                        </div>:null
                    }
                </div>
            </div>
    </div>
        <ToastContainer />

    </div>
  );
}

export default App;
